.background{
  background-image: linear-gradient(to bottom, #abffff, #8ceafd, #78d4f9, #73bcf1, #7ba2e3);
  width: 100%;
  padding: 20px 0px;
  height: 100vh;
  overflow-y: scroll;
}

.poppins-regular {
  font-family: 'Trebuchet MS', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: 'Trebuchet MS', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.card {
  border-radius: 0.75rem !important;
  border: 0.5vmin solid #454545 !important;
  box-shadow: 0.2rem 0.2rem #454545;
  overflow: hidden !important;
  background-color: rgb(233, 200, 116) !important;
}

.card:hover {
  border: 1px solid black;
  transform: scale(1.05);
}

.card:active {
  transform: scale(0.95);
}

.poppins-bold {
  font-family: 'Trebuchet MS', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.social-icon:hover {
  /* border: 1px solid black; */
  transform: scale(1.05);
}

.social-icon:active {
  transform: scale(0.95);
}